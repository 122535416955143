/* lobster-400normal - latin */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Lobster Regular '),
    local('Lobster-Regular'),
    url('./files/lobster-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/lobster-latin-400.woff') format('woff'); /* Modern Browsers */
}

